@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
body {
   background: white;
   font-family: 'Staatliches', cursive;
   color: black;
}
select {
   cursor: pointer;
}
input {
   cursor: pointer;
}
@media (prefers-color-scheme: dark) {
 body {
    background: #18181b;
    color: white;
 }
}
